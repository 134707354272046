import React, { Fragment, useState } from "react";
import Html5QrcodePlugin from "./htmlDecode";
import BarcodeScanner from "./barcoderjs";
import RoutesApp from "./routes/Routes";
import EventProvider from "./provider/EventProvider";
import {AuthProvider} from "./provider/AuthProvider";

const App = () => {
  return (
    <Fragment>
      <AuthProvider>
        <RoutesApp />
      </AuthProvider>
    </Fragment>
  );
};

export default App;

/*
const App = (props) => {
const [decode,setDecode] = useState(false)
const [decodeText,setDecodeText] = useState("")
  const onNewScanResult = (decodedText, decodedResult) => {
    console.log(decodedText);
    console.log(decodedResult);
      // handle decoded results here
    //  alert(" DEcode TExt", decodedText)
      setDecode(decodedResult)
      setDecodeText(JSON.stringify(decodeText))
      //, "decodedText:  ",decodedText 
  };
  return (
    <div className="App">
      <BarcodeScanner />
    </div>
  );
/*
  return (
      <div className="App">
          <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}

          />
        { decode && <div>
                 
         DecodedText: {decode.decodedText}
         <br></br>
         <br></br>
         Format: {JSON.stringify(decode.result.format)}
         <br></br>
                     </div>}
      </div>
      
  );
};


export default App;
*/
