import http from "./http";
import config from '../config/config'

export const generateReportByDate = async (start, end, callback) => {
  try {
    const URL = `${config.URL_BASE_API}/event/reportByDate?start=${start}&end=${end}`;


    const response = await http.get(URL);

    if (response.status !== 200) callback(null);

    return callback(response.data);
  } catch (error) {
    return callback(null);
  }
};
