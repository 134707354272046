import AWS from "aws-sdk";
import { getCookie } from "../utils/login";
import config from "../config/config";

AWS.config.update({
  region: config.REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.POOL_ID,
  }),
});

export const isUserAdmin = async (token, callback) => {
  var params = {
    AccessToken: token ?? getCookie(),
  };
  const cognitoidentityserviceprovider =
    new AWS.CognitoIdentityServiceProvider();

  cognitoidentityserviceprovider.getUser(params, function (err, data) {
    if (err) {
      console.log(err, err.stack); // an error occurred
      callback({error: true, code: err.code})}
    else {
      console.log(data);
      const isAdmin = data.UserAttributes.find(
        (item) => item.Name == "custom:isAdmin"
      );

      if (!isAdmin || isAdmin.Value !== "1") return callback({message: "ok", isAdmin: false});
      return callback({message: "ok", isAdmin: true});
    }
  });
};

