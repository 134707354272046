
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import {Scanner} from "../components/ScanBarcode";
import ModalClient from "../components/Modal";

export default () => {
    const [decodedValue, setDecodedValue] = useState("");
    const [scannerType, setScannerType] = useState("BAR");
    const { eventId } = useParams();
    const [open, setOpen] = useState(false);
    const [clientData, setClientData] = useState(null);
    const [clientEnable, setClientEnable] = useState(false);
    const [userExceededEvents, setUserExceededEvents] = useState(false);
    const [drinksEnabled, setDrinksEnabled] = useState(0);
    const  handleCloseModal = () => setOpen(false);
    console.log(`Se ingreso al evento ${eventId}`);
    
    return (
      <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/back.jpg)` , backgroundSize: "cover", backgroundPosition: "center", width: '100vw', height: "100vh"}} >
        <div style={{display:"flex", justifyContent:"center", alignContent:"center", paddingTop:"2rem"}} ><img src={process.env.PUBLIC_URL + '/logo-byc.png'} alt="byc" /></div>
        <Scanner type={scannerType} onResult={(res) => setDecodedValue(res)} eventId={eventId} setClientEnable={setClientEnable} setOpenModal={setOpen} setClientData={setClientData} setDrinksEnabled={setDrinksEnabled} setUserExceededEvents={setUserExceededEvents}/>
        <ModalClient drinksEnabled={drinksEnabled}  handleClose={handleCloseModal} open={open} clientEnable={clientEnable} client={clientData} userExceededEvents={userExceededEvents}/>
      </ div>
    );
}






