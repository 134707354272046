import React, { useEffect, useState, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import Button from "./Button";
import Select from "./Select";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CreateIcon from '@mui/icons-material/Create';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import EditOffIcon from '@mui/icons-material/EditOff';
import FieldText from "./FieldText";
import DoneIcon from '@mui/icons-material/Done';
import { assingDrinkToCustomer } from "../services/assignCustomerEvent";
import {  decodeBarcode } from "../utils/decodeBarcode";
import BasicButtons from "../components/Button";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// import { getCameraList } from "./Utils";

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
const brConfig = { fps: 10, qrbox: { width: 300, height: 150 } };
let html5QrCode;

// function startCamera(){}

export const Scanner = (props) => {
  const fileRef = useRef(null);
  const [cameraList, setCameraList] = useState([]);
  const [activeCamera, setActiveCamera] = useState();
  const [scanning, setScanning] = useState(false);
  const [userInput, setUserInput] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [open, setOpen] = useState(false);
  const eventId = props.eventId;
  const setClientData = props.setClientData;
  const setDrinksEnabled = props.setDrinksEnabled;


  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
    getCameras();
    const oldRegion = document.getElementById("qr-shaded-region");
    oldRegion && oldRegion.remove();
  }, []);

  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      console.info(decodedResult, decodedText);
      props.onResult(decodedText);
     // alert(`decoded:__ ${decodedText}`);
     //alert(JSON.stringify(decodeBarcode(decodedText)))
       const client =  decodeBarcode(decodedText)
       handleSentData(client);
       setClientData(client)
       setScanning(false);
       handleStop();
    };
    html5QrCode
      .start(
        { facingMode: "environment" },
        props.type === "QR" ? qrConfig : brConfig,
        qrCodeSuccessCallback
      )
      .then(() => {
        // const oldRegion = document.getElementById("qr-shaded-region");
        // if (oldRegion) oldRegion.innerHTML = "";
      });
  };
  const getCameras = () => {
    Html5Qrcode.getCameras()
      .then((devices) => {
        /**
         * devices would be an array of objects of type:
         * { id: "id", label: "label" }
         */
        console.info(devices);
        if (devices && devices.length) {
          setCameraList(devices);
          setActiveCamera(devices[0]);
        }
      })
      .catch((err) => {
        console.error(err);
        setCameraList([]);
      });
  };
 
  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleStartScanning = () => {
    setScanning(true);
    handleClickAdvanced();
  }

  const handleUserInput = () => {
    setUserInput(true);
    setScanning(true)
  }
  const handleStopUserInput = () => {
    if (textInput.length >= 8 && textInput != '1245678'){

    setUserInput(false);
    setScanning(false);
    setClientData(null);
    handleSentData(null); // Envía datos al backend
    return;
    }

    setOpen(true);
  }

  const handleStopScanning = () => {
    setScanning(false);
    handleStop();
  }

  const cancelOperacionInput = () => {
    setUserInput(false);
    setScanning(false);
  }
  

  const handleSentData = (clientScan) => {


    assingDrinkToCustomer(eventId,clientScan ?? textInput,1, (state) => {

      if (state?.code) {
        props.setClientEnable(false);
        props.setUserExceededEvents(state)
        props.setOpenModal(true)
        return;
      }
      console.log(state);
      props.setOpenModal(true);
      setDrinksEnabled(state?.drinksEnabled)
      props.setClientEnable(state);
    })
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



  return (
    <div style={{display: "flex", justifyContent:"center", gap:"1rem", flexDirection:"column"}}>
      <div id="reader" width="100%"></div>
    { !scanning &&  <Button  onClick={() => handleStartScanning()}  text="Escanear" color="primary"  endIcon={<QrCode2Icon/>}></Button> }
     {scanning && !userInput && <Button  onClick={() => handleStopScanning()}  text="Cancelar" color="error"  endIcon={<StopCircleIcon/>} ></Button>}
     { !scanning &&  <Button  onClick={() => handleUserInput()}  text="Ingresar Manualmente" color="primary"  endIcon={<CreateIcon/>}></Button> }
     {  userInput && <div style={{marginInline: "4rem"}} ><FieldText  onkeyDown={handleStopUserInput} onChange={setTextInput}  ></FieldText></div> }
     {  userInput && <Button  onClick={() => handleStopUserInput()}  text="Aceptar" color="success"  endIcon={<DoneIcon/>} ></Button>}
     {  userInput && <Button  onClick={() => cancelOperacionInput()}  text="Cancelar" color="error"  endIcon={<EditOffIcon/>} ></Button>}
     { !scanning  &&         <div style={{position:"absolute", bottom:"0"}} ><BasicButtons onClick={() => {window.location.replace('/')}} text={"Salir"} endIcon={<KeyboardReturnIcon/>} color={"warning"} height="2.5rem"></BasicButtons></div>}
     <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            El dato ingresado debe contener 8 caracteres   
          </Alert>
        </Snackbar>

    </div>
  );
};


/*
      <Select cameraList={cameraList} onCameraChange={onCameraChange} activeCamera={activeCamera} ></Select>

      {cameraList.length > 0 && (
        <select onChange={(e) => onCameraChange(e)}>
          {cameraList.map((li) => (
            <option
              key={li.id}
              id={li.id}
              selected={activeCamera && activeCamera.id === li.id}
            >
              {li.label}
            </option>
          ))}
        </select>
      )}*/