import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function BasicButtons({text, onClick,color, endIcon, height, margin}) {
  return (
      <Button 
      onClick={onClick}
      variant="contained"
      color={color}
      size='large'
      sx={{margin: margin ?? "4rem", height: height ?? "7rem", fontWeight:'bold'}}
      endIcon={endIcon}
      >{text}</Button>
  );
}