import { useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import {  getCookie,isLogged,setCookie} from "../utils/login";
import { isUserAdmin  } from "../services/getUserCognito";


export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [waitForLogin, setWaitForLogin] = useState(true);


 
    const urlLogin = 'https://login-event.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=6f6hab89dm40s43t9itnegoaq1&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://bycventas.com';


    useEffect(() => {
        const accessToken =  decodeHash();
        if (!isAuthenticated) setIsAuthenticated(isLogged());
        
        if (!isAuthenticated && !accessToken ) return window.location.replace(urlLogin);
        
        if (!isAuthenticated) setCookie(accessToken)
    
        console.log(accessToken);

        // Funcion que valida jwt con cognito
        isUserAdmin(accessToken,(state) => {
    
    
          console.log(state.code);
          
          if (state.code){
            window.location.replace(urlLogin)  
          }
    
          if (state.isAdmin) setIsAdmin(true)

          if (!isAuthenticated) setIsAuthenticated(accessToken)
    
          console.log(isAuthenticated);
          setWaitForLogin(false);
          
          //window.location.replace('/events')
        })
      }, []);

   

  return (
    <AuthContext.Provider
      value={{
       isAuthenticated,
       isAdmin,
       waitForLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};



const decodeHash = () => {
    const queryString = window.location.hash.substring(1);
    const params = new URLSearchParams(queryString);
    return params.get("access_token") ?? getCookie()
  
  };
  