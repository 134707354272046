import http from "./http";
import config from '../config/config'

export const getAllEvents = async (callback) => {

  const URL =  `${config.URL_BASE_API}/event/allEvents?open=true`;
 
  const response = await http.get(URL);

  if (response.status !== 200) return callback(null);

  return callback(response.data);
};
