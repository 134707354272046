import React, {cloneElement, useState, useEffect, useContext} from "react";
import { Route, Navigate } from "react-router-dom";
import {  getCookie,isLogged,setCookie} from "../utils/login";
import { isUserAdmin  } from "../services/getUserCognito";
import AuthContext from "../context/AuthContext"

const ProtectedRoute = ({ children,checkAdmin, ...rest }) => {
  const { isAuthenticated , isAdmin} = useContext(AuthContext)


    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }
    if (checkAdmin && !isAdmin) return <Navigate to="/" replace />;
  
    return children;
  };
export default ProtectedRoute;


