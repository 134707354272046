import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function FormPropsTextFields({onChange, onkeyDown}) {


function handleKeyDown(event) {
  if (event.keyCode === 13) {
    // El usuario presionó la tecla Enter, realizar acción aquí
    onkeyDown();
  }
}
  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 1, maxWidth: '15rem' },
      }}
      noValidate
      autoComplete="off"
    >
    
    <TextField
          variant="filled"
          id="outlined-helperText"
          label="Ingresar"
          defaultValue="Default Value"
          type="number"
          onChange={(e) => onChange(e.target.value)}
          sx={{margin:"4rem", color:'white', 
          input: {
            color: "orange",
            background: "white"
          } }}
          autoFocus
          onKeyDown={handleKeyDown}  
          color='warning' 
          
            

        />
    </Box>
  );
}