import React, {useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Title from "./Title";
import Divider from '@mui/material/Divider';
import { TextField } from '@mui/material';
import BasicButtons from './Button';
import {  closeEvent } from "../services/closeEvent";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function CheckboxList({ list, setReloadList, realoadList}) {
  const [checked, setChecked] = useState([]);
  const [keyClose, setKeyClose] = useState('');
  const [eventId, setEventId] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleToggle = (value) => () => {
    if (checked.length === 1 && checked[0] === value) {
      // Si ya hay un check seleccionado y es el mismo que el usuario intenta seleccionar nuevamente,
      // no se realiza ningún cambio.
      return;
    }

    const currentIndex = checked.indexOf(value);
    const newChecked = currentIndex === -1 ? [value] : [];

    setEventId(value);
    setChecked(newChecked);
  };

  const handleChangeKeyClose = (value) => {
    setKeyClose(value);
  }


  const handleSent = () => {
    setLoading(true);
    setError(false);

    if (eventId) {
      closeEvent(eventId,keyClose,(state) => {
        setLoading(false);
        if (!state) setError(true);
        setOpen(true);
        setReloadList(!realoadList);
        clearField();
    })
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  
  const clearField = () => {
    setKeyClose('')
  }
  return (
    <div>
       {list.length > 0 && <div>
        <Title>Eventos abiertos: {list.length}</Title>
        <Divider/>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' , marginLeft:"1rem"}}>
      {list.map((value) => {
        if (value.active){


        const labelId = `checkbox-list-label-${value.eventId}`;

        return (
          <ListItem
            key={value.eventId}
 
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleToggle(value.eventId)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value.eventId) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`Evento: ${value.name}`} />
            <Divider/>
            </ListItemButton>
          </ListItem>
        );
      
        }
      })}
    </List>
     <div     style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2rem",
          flexDirection:'column'
        }} > 
     <TextField
          required
          id="outlined-required"
          label="Llave de cierre"
          onChange={(e) => handleChangeKeyClose(e.target.value)}
          value={keyClose}
          color='primary'
        />
        <BasicButtons color={'warning'} text={'Cerrar evento'} height={"3rem"}  onClick={handleSent} ></BasicButtons>
     </div>
     <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={ error ? 'error' : 'success'} sx={{ width: '100%' }}>
          {!error ? `El evento se cerró satisfactoriamente.` : `Error al cerrar el evento.`}
        </Alert>
      </Snackbar>
        </div>}
    </div>
  );
}
