import { Typography } from '@mui/material';



export default ({children}) => {
    return (
        <Typography fontWeight={"bold"} variant='h3' margin={"1rem"} >
            {children}
        </Typography>
    )

}