import http from "./http";
import config from '../config/config'

export const createEvent = async (name, keyClose, callback) => {
  try {
    const URL = `${config.URL_BASE_API}/event/createEvent`;

    const body = {
      name: name,
      keyClose: keyClose,
      clients: [],
      drinksConsumed: 0,
      active: true,
    };

    const response = await http.post(URL, body);

    if (response.status !== 200) callback(null);

    return callback(response.data);
  } catch (error) {
    return callback(null);
  }
};
