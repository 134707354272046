import { useContext, useEffect, useState } from "react";
import { isUserAdmin } from "../services/getUserCognito";
import { deleteCookie } from "../utils/login";
import Button from "../components/Button";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Events from "./Events";
import AuthContext from "../context/AuthContext";
import BasicButtons from "../components/Button";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';


export default () => {
  const urlLogOut = 'https://login-event.auth.us-east-1.amazoncognito.com/logout?client_id=6f6hab89dm40s43t9itnegoaq1&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://bycventas.com'
  const {isAdmin , isAuthenticated} = useContext(AuthContext)

  const handleLogOut = () => {
      deleteCookie();
      window.location.replace(urlLogOut);
  }


  return (
    <div>
        {isAuthenticated && <Events></Events>}
        {isAdmin && <div style={{display: 'flex', justifyContent: 'center' , flexDirection:'column'}} >
            <Button endIcon={<AdminPanelSettingsIcon/>} margin={'2rem'} color='warning' text={'Administrar'} height='4rem' onClick={() => window.location.replace('/admin/backoffice')} ></Button> 
        </div>}
        {isAuthenticated && <BasicButtons margin={'2rem'} onClick={handleLogOut} text={"Cerrar Sesión"} endIcon={<KeyboardReturnIcon/>} color={"warning"} height="2.5rem"></BasicButtons>}    

       

    </div>
  );
};
