import React, { Fragment, lazy, Suspense, useContext } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Events from "../pages/Events";
import Event from "../pages/Event";
import Login from "../pages/Login";
import BackOffice from "../pages/BackOffice";
import AuthContext from "../context/AuthContext";
import ProtectedRoute from "./PrivateRoute";

const RoutesApp = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { waitForLogin } = useContext(AuthContext);

  if (waitForLogin) return <Fragment/>;

   //alert('Rutas')
  return (
    <Routes>
        <Route
          path="/"
          element={
            <Login accessToken={searchParams.get("access_token")
          
          } />
          }
        />
          <Route
          path="/events"
          element={
            <ProtectedRoute children={<Events/>} />
          }
        />
         <Route
          path="/event/:eventId"
          element={
            <ProtectedRoute children={<Event/>} />
          }
        />
        <Route path="/admin/backoffice"
        element={<ProtectedRoute checkAdmin={true} children={<BackOffice/>} />} />
    </Routes>
  );
};

export default RoutesApp;
