import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DoneIcon from '@mui/icons-material/Done';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';



export default function BasicModal({clientId, clientEnable, open, handleClose, client , userExceededEvents, drinksEnabled}) {

  console.log(userExceededEvents);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -100%)',
        width: 250,
        heigth: 500,
        bgcolor: 'background.paper',
       // border: '2px solid #000',
        boxShadow: 24,
        p: 5,
        backgroundColor: clientEnable ? 'green' : 'red',
        color: 'white'
      };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography noWrap  id="modal-modal-title" variant="h6" component="h2">
           {client && <div> El cliente: <br></br> {client?.name} {client?.surname}</div>}
          </Typography>
          <Typography>{client && <div>Id de usuario:  {client.clientId}</div> }</Typography>
          <br></br>
         {!userExceededEvents &&  <Typography variant="h6" component="h2">  {clientEnable ? '¡Utilizó una consumisión!' : '¡Agotó todas las consumisiones disponibles!'}</Typography>}
            <Typography variant="h6" component="h2" >Disponibles: {drinksEnabled ?? '0'}</Typography>
        
         {userExceededEvents &&  <Typography  variant="h6" component="h2">El cliente está registrado <br></br> en el evento: <br></br> <b>{userExceededEvents.event.name}</b></Typography>}

          <br></br>

            <Box sx={{display:"flex",justifyContent: "center"}} >
            {clientEnable ? <DoneIcon fontSize='large' sx={{marginTop:"2rem"}} /> : <DoNotDisturbIcon fontSize='large' sx={{marginTop:"2rem"}} />}
            </Box>
        </Box>
      </Modal>
    </div>
  );
}