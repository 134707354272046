import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "./Button";
import { createEvent } from "../services/createEvent";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Title from "./Title";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function validateinput(input) {
  // Verificar si la input está vacía
  if (input.length === 0) {
    return false;
  }

  return true;
}

export default function FormPropsTextFields({realoadList, setReloadList}) {
  const [eventName, setEventName] = useState("");
  const [keyClose, setKeyClose] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleChangeName = (name) => setEventName(name);
  const handleChangeKeyClose = (key) => setKeyClose(key);

  const handleSent = () => {
    console.log(eventName, keyClose);
    setLoading(true);

    if (!validateinput(eventName) || !validateinput(keyClose)) {
      setLoading(false);
      setError(true);
      console.log("mmm");
      return;
    }

    createEvent(eventName, keyClose, (state) => {
      setLoading(false);
      setError(!state);
      setOpen(true);
      setReloadList(!realoadList);
      clearFields();
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const clearFields = () => {
    setEventName("");
    setKeyClose("");
  };
  

  return (
    <div>
      <Title>Crear Evento</Title>

      <Box
        sx={{
          display: "flex",
          margin: "2rem",
          border: "1px solid orange",
          borderRadius: "3rem",
          maxWidth: "20rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "2rem",
            gap: "0.5rem",
          }}
        >
          <TextField
            required
            id="outlined-required"
            label="Nombre"
            onChange={(e) => handleChangeName(e.target.value)}
            value={eventName}
            
          />
          <TextField
            required
            id="outlined-required"
            label="Llave de cierre"
            onChange={(e) => handleChangeKeyClose(e.target.value)}
            value={keyClose}
            color="primary"
          />
          <Button
            color={"warning"}
            height={"3rem"}
            text={
              loading ? <CircularProgress color="inherit" /> : "Crear Evento"
            }
            endIcon={<SaveIcon />}
            margin={"1rem"}
            onClick={handleSent}
          ></Button>
        </Box>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {!error
              ? `El evento ${eventName} se creo satisfactoriamente `
              : `Error al crear el evento: ${eventName}`}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
}
