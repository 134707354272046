import {useContext} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import EventContext from '../context/EventContext';

export default function EventCard({id,name,onClick}) {
    
  return (
    <Card sx={{ maxWidth: 345, margin: "1rem" }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Evento disponible
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => window.location.replace(`/event/${id}`)}>
          Ingresar 
        </Button>
      </CardActions>
    </Card>
  );
}