import { useEffect, useState } from "react";
import FormPropsTextFields from "../components/FormCreateEvent";
import CheckboxList from "../components/ListEventsToDelete";
import { getAllEvents } from "../services/events";
import BasicButtons from "../components/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import DatePicker from "../components/DatePicker";
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';


export default () => {
  const [list, setList] = useState([]);
  const [realoadList, setReloadList] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    getAllEvents((state) => {
      setList(state);
    });
  }, [realoadList]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2rem",
        }}
      >
        <img src={process.env.PUBLIC_URL + "/logo-byc.png"} alt="byc" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2rem",
          flexDirection: matches ? "row" : "column",
          gap: matches ? "10rem" : "2rem"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "2rem",
          }}
        >
          <FormPropsTextFields
            realoadList={realoadList}
            setReloadList={setReloadList}
          ></FormPropsTextFields>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "2rem",
          }}
        >
          <DatePicker />
        </div>
      </div>
      <Divider/>
      
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2rem",
        }}
      >
        <CheckboxList
          realoadList={realoadList}
          setReloadList={setReloadList}
          list={list}
        ></CheckboxList>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2rem",
        }}
      >
        <BasicButtons
          onClick={() => {
            window.location.replace("/");
          }}
          text={"Salir"}
          endIcon={<KeyboardReturnIcon />}
          color={"warning"}
          height="2.5rem"
        ></BasicButtons>
      </div>
    </div>
  );
};
