import React, { Component } from 'react';
import jsQR from "jsqr";

class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.startCamera();
  }

  startCamera() {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        this.videoRef.current.srcObject = stream;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  scanBarcode() {
    const video = this.videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" });
    if (code) {
        alert(code.data)
      console.log(code.data);
    } else {
        //alert("Mmm... timeout")
      setTimeout(() => this.scanBarcode(), 500);
    }
  }

  render() {
    return (
      <div>
        <video ref={this.videoRef} autoPlay={true} />
        <button onClick={() => this.scanBarcode()}>Scan Barcode</button>
      </div>
    );
  }
}

export default BarcodeScanner;
