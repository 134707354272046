import { saveAs } from 'file-saver';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { format } from 'date-fns';

export const handleDownloadPDF = async (jsonData) => {
  try {
    const backImageBytes = await fetch(`${process.env.PUBLIC_URL}/logo-byc.png`).then((res) => res.arrayBuffer());

    // Generar el PDF
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    const tableX = 50;
    let y = height - 50;

    // Agregar la imagen centrada al inicio del PDF
    const backImage = await pdfDoc.embedPng(backImageBytes);
    const backImageDims = backImage.scale(0.5); // Ajusta el tamaño de la imagen según sea necesario
    const backImageX = (width - backImageDims.width) / 2;
    const backImageY = (height - backImageDims.height) / 2;

    page.drawImage(backImage, {
      x: backImageX,
      y: backImageY,
      width: backImageDims.width,
      height: backImageDims.height,
    });

    y -= 20;

    // Agregar una tabla con los datos
    const table = [
      ['Evento', 'Bebidas', 'Fecha de Creación'],
      ...jsonData.map((item) => [
        item.eventName,
        item.drinks.toString(),
        format(new Date(item.created), 'dd/MM/yyyy HH:mm:ss'), // Formatear la fecha utilizando date-fns
      ]),
    ];

    const tableWidths = [200, 100, 150]; // Ajusta el ancho de cada columna según sea necesario

    const cellMargin = 5; // Margen alrededor del texto dentro de las celdas

    table.forEach((row, rowIndex) => {
        row.forEach((cell, columnIndex) => {
          const cellX = tableX + tableWidths.slice(0, columnIndex).reduce((a, b) => a + b, 0);
          const cellY = y - rowIndex * 15;
          const textX = cellX + cellMargin; // Ajustar posición X con margen
          const textY = cellY + cellMargin; // Ajustar posición Y con margen
      
      
          page.drawText(cell.toString(), {
            x: textX,
            y: textY,
            size: fontSize,
            font,
            fillColor: rgb(0, 0, 0), // Ajusta el color de texto según sea necesario (en este caso, negro)
          });
      
          // Aplicar estilos a las celdas
          page.drawRectangle({
            x: cellX,
            y: cellY,
            width: tableWidths[columnIndex],
            height: 15,
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            fillOpacity: 0.2,
    
           // color: rgb(1, 1, 1), // Valores normalizados en el rango de 0 a 1 (color blanco)
          });
        });
      });
    // Descargar el PDF
    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    saveAs(pdfBlob, 'reporte_evento_byc.pdf');
  } catch (error) {
    console.error('Error al generar o descargar el PDF', error);
  }
};
