import React, { useState } from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BasicButtons from "../components/Button";
import SendIcon from "@mui/icons-material/Send";
import Title from "./Title";
import { generateReportByDate } from "../services/generateReport";
import CircularProgress from "@mui/material/CircularProgress";
import { handleDownloadPDF } from "../services/saveAsPfd";
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'dayjs/locale/es';
import { Box } from "@mui/system";


export default () => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const format = "DD-MM-YYYY";

  const handleGenerateReport = () => {
    setLoading(true)
    generateReportByDate(startTime, endTime, (state) => {
      setLoading(false);
      console.log(state);
      handleDownloadPDF(state);
    });
  };

  return (
    <div>    <Title>Generar reporte</Title>
         <Box
        sx={{
          display: "flex",
          margin: "2rem",
          border: "1px solid orange",
          borderRadius: "3rem",
          maxWidth: "20rem",
        }}
      >
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "2rem",
            gap: "0.5rem",
          }}
        >  

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2rem",
          gap: "2rem",
          flexDirection: "column",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DemoContainer components={["DatePicker"]}  >
            <DatePicker
              format={format}
              value={startTime}
              onChange={(newValue) => setStartTime(newValue)}
              label="Desde"
              localeText={es}
            />
          </DemoContainer>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              format={format}
              value={endTime}
              onChange={(newValue) => setEndTime(newValue)}
              label="Hasta"
            />
          </DemoContainer>
        </LocalizationProvider>
        <BasicButtons
          margin={"1rem"}
          onClick={handleGenerateReport}
          text={ loading ? <CircularProgress color="inherit" sx={{fontSize:"2rem", marginInline:"3.5rem"}} /> :"Generar" }
          endIcon={<SendIcon />}
          color={"warning"}
          height="2.5rem"
        ></BasicButtons>
      </div>
      </Box>
        </Box>

    </div>
  );
};

