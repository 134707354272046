import http from "./http";
import config from '../config/config'


export const assingDrinkToCustomer = async (eventId, client, drinksConsumed = 1,callback) => {

    try {
      
  const URL = `${config.URL_BASE_API}/client/assignClient`;

  console.log("Cliente previo al reques:: ", client);

  const body = {
     id: client?.clientId ?? client,
    event: eventId,
    drinksConsumed: drinksConsumed,
    name: client?.name ?? '',
    surname: client?.surname ?? '',
    date: client?.date ?? ''

  }

  console.log(body);
  const response = await http.post(URL,body);

  if (response.status !== 200) {
        if (response.status === 401) callback({code: response.data.code, eventName:response.data?.event?.name , drinksEnabled: response.data?.drinksEnabled })
        callback(null)
  }

  return callback(response.data);
    } catch (error) {
      console.log("Error::: ", error.response.data);

      if (error?.response?.data?.code == "USER_EXIST_EVENT" ) return callback(error.response.data)
      
      return callback(null)
    }
};
