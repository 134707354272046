import { Fragment, useContext, useEffect, useState } from "react";
import EventCard from "../components/Card";
import Title from "../components/Title";
import EventContext from "../context/EventContext";
import { getAllEvents } from "../services/events";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import StadiumIcon from '@mui/icons-material/Stadium';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemButton from '@mui/material/ListItemButton';



export default () => {
  const [events, setEvents] = useState(null);
  //const {mainEvent, setMainEvent} = useContext(EventContext);

  useEffect(() => {
    //console.log(mainEvent);
    getAllEvents((data) => {
      console.log(data);
      if (data) setEvents(data);
    });
  }, []);
  


  
  return (
    <Fragment>
      {!events && <div><LinearProgress /></div>}
      {events && (
        <div  style={{display: "flex", justifyContent:"center", gap:"1rem", flexDirection:"column"}} >
            <Title>Eventos</Title>
            <List>
            
          
          {events.map((event) => {
            if (event.active) return (
              <div key={event._id} >
                <Divider/>
               <ListItemButton sx={{paddingBottom:"2rem", paddingTop:"2rem"}} onClick={() => window.location.replace(`/event/${event.eventId}`)} >
                  <ListItemIcon>
                    <StadiumIcon />
                  </ListItemIcon>
                  <ListItemText

                    primary={event.name}
                    secondary={"Evento Disponible"}
                  />
                </ListItemButton>
                <Divider/>
                
              </div>
            );
          })}
            </List>
        </div>
      )}
    </Fragment>
  );
};
