import Cookies from "universal-cookie";


export const isLogged = () => {
    const cookie = new Cookies();
    return cookie.get("session");
}

export const setCookie = (jwt) => {
    document.cookie = `session=${jwt}; path=/; max-age=43200;`;
}


export const getCookie = () => {
    const cookie = new Cookies();
    return cookie.get("session");
}

export const deleteCookie = () => {
    const cookie = new Cookies();
    return cookie.remove('session')
}