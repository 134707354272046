import { useState } from "react";
import EventContext from "../context/EventContext";


export default ({children}) => {
    const [mainEvent, setMainEvent] = useState(null);

    return(
        <EventContext.Provider value={{mainEvent, setMainEvent}} >{children}</EventContext.Provider>
    )
}