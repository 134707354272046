export const decodeBarcode = (barcode) => {

    /**
     *  numero tramite 1
     * @ apellido 2
     * @ nombre 3
     * @ genero 4
     * @ dni 5
     * @ desconocido 6
     * @ fecha de nacimiento 7 
     * @ Fecha de emision 8
     */

    const decode = barcode.split("@");

    return {
            name: decode[2],
            surname: decode[1],
            clientId: decode[4],
            date: decode[6]
    }

}