import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectAutoWidth({cameraList, onCameraChange,activeCamera}) {
  const [camera, setCamera] = React.useState('');

  const handleChange = (event) => {
    setCamera(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Cámara</InputLabel>
        {cameraList.length > 0 && (
        <Select
          labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={camera}
        autoWidth
        label="Camera"        
        onChange={(e) => {onCameraChange(e); handleChange(e)}}>
          {cameraList.map((li) => (
            <MenuItem
              key={li.id}
              id={li.id}
              selected={activeCamera && activeCamera.id === li.id}
              value={li.label}
            >
              {li.label}
            </MenuItem>
          ))}
        </Select>
      )}
      </FormControl>
    </div>
  );
}